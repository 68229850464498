<template>
	<div class="py-4 md:px-12">
		<div class="max-w-3xl pt-4 mx-auto">
			<h2 class="pb-4 text-3xl font-bold tracking-tight text-center text-gray-900 sm:text-4xl">
				FAQ’s
			</h2>
			<dl>
				<Disclosure
					v-for="(faq) in faqs"
					:key="translate(faq.question)"
					v-slot="{ open }"
					as="div"
					class="py-3 border-b border-gray-200"
					@mouseenter="handleHover(translate(faq.question))"
				>
					<dt class="text-lg">
						<DisclosureButton
							class="flex items-center justify-between w-full text-left text-gray-400"
							@click="handleClick(translate(faq.question))"
						>
							<span class="font-medium text-gray-900">
								{{ translate(faq.question) }}
							</span>
							<span class="flex items-center ml-6 h-7">
								<ChevronDownIcon
									:class="[
										{ 'transform -rotate-180': open },
										'h-6 w-6 transition-all ease-in text-gray-900 text-center',
									]"
									aria-hidden="true"
								/>
							</span>
						</DisclosureButton>
					</dt>
					<transition name="fade-and-slide">
						<DisclosurePanel
							as="dd"
							class="pr-12 mt-2"
						>
							<p
								class="text-base text-gray-500"
								v-html="translate(faq.answer)"
							/>
						</DisclosurePanel>
					</transition>
				</Disclosure>
			</dl>
		</div>
	</div>
</template>

<script setup lang="ts">
import { toRefs } from 'vue'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/24/outline'
import { useI18n } from 'vue-i18n'
import type { FaqProps } from '@/components/faq/types'
import type { FAQEvent } from '@/types/gtm/events'
import { FAQ_HOVER, FAQ_CLICK } from '@/constants/gtm/events'
import { useTrackEvent } from '@/composables/useEventTracking'

const props = defineProps({
	faqs: {
		type: Object as PropType<FaqProps[]>,
		required: true
	},
	faqsFromStoryBlok: {
		type: Boolean,
		default: false
	}
})

const { faqs, faqsFromStoryBlok } = toRefs(props)

const { t, rt } = useI18n({
	useScope: 'global'
})

const translate = (input: string) => {
	return faqsFromStoryBlok.value ? t(input) : rt(input)
}

function handleHover (question: string) {
	const trackedEvent: FAQEvent = {
		event: FAQ_HOVER,
		category: 'FAQ',
		action: 'hover',
		label: 'FAQ Hover',
		question
	}
	useTrackEvent(trackedEvent)
}

function handleClick (question: string) {
	const trackedEvent: FAQEvent = {
		event: FAQ_CLICK,
		category: 'FAQ',
		action: 'click',
		label: 'FAQ Click',
		question
	}
	useTrackEvent(trackedEvent)
}
</script>
